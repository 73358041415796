import autosize from "autosize";

$(document).on("ready turbolinks:load", function() {
  // Add date selector to avoid user input errors
  $("#id_date, .dateinput").pickadate({
    format: "yyyy-mm-dd",
    formatSubmit: "yyyy-mm-dd",
    hiddenName: true
  });

  // Autosize text areas to look nicer
  $("textarea").attr("rows", "2");
  autosize($("textarea"));

  // Prevent double form submissions
  $("form").submit(() => {
    $(this)
      .find("button[type='submit']")
      .prop("disabled", true);
  });
});
