// popper.js
// Hover over popups, bootstrap peer

import "popper.js";

// Bootstrap
// Mostly used for basic layout/grid

import "bootstrap";

// Fontisto
// Font icons

import "fontisto/css/fontisto/fontisto.css";

// Moment
// Datetime improvements

import "moment";

// Pickadate
// Both date picker and time picker improvements for forms

import "picker";
import "picker-date";
import "picker-time";

import "pickadate/lib/themes/classic.css";
import "pickadate/lib/themes/classic.date.css";
import "pickadate/lib/themes/classic.time.css";

// Fullcalendar
// A full calendar display like google calendar

import "fullcalendar";

import "fullcalendar/dist/fullcalendar.css";

// Slick-carousel
// Responsive slider/carousel

import "slick-carousel";
import "slick-carousel/slick/slick.min";

import "slick-carousel/slick/slick.css";

// Fancybox
// Lightbox for galleries

import "@fancyapps/fancybox";

import "@fancyapps/fancybox/dist/jquery.fancybox.css";

$.fancybox.defaults.hash = false; // Disable history which bugs out with Turbolinks enabled
