$(document).on("ready turbolinks:load", function() {
  $("#homeSlider").slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: $("#homeDots"),
    autoplay: true
  });
});

$(document).on("turbolinks:before-cache", () => {
  $("#homeSlider").slick("unslick");
});
